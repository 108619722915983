/* h1 {
  color: white;
  font-weight: 700;
  font-size: clamp(0.063rem, 5vw + 0.25rem, 8rem);
  line-height: 90%;
} */

p {
  font-weight: 400;
  font-size: clamp(0.75rem, 5vw - 1rem, 3.125rem);
  line-height: 100%;
}
