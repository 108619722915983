.wrapper-cards {
  margin-top: 10%;
}

.cards-title {
  color: #24568e;
  font-size: clamp(0.063rem, 4vw + 0.25rem, 8rem);
  font-weight: 700;
  font-family: "Bebas Neue", sans-serif;
  text-align: center;
}

.carousel-wrapper {
  margin-top: 5%;
}

.carousel-inner {
  border-radius: 30px;
}

.d-block {
  width: 100%;
}

.title-card {
  color: white;
  font-weight: 700;
  font-family: "Bebas Neue", sans-serif;
  font-size: clamp(0.063rem, 4vw + 0.25rem, 8rem);
}
