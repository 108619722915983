.contacto {
  margin-top: 10%;
}

.wrap-title-contact {
  background: #e25b22;
  padding: 5% 5% 3% 5%;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-contact {
  color: white;
  font-size: clamp(0.063rem, 4vw + 0.25rem, 8rem);
  font-weight: 700;
  font-family: "Bebas Neue", sans-serif;
  line-height: 90%;
}

.Frame {
  background: #24568e;
  padding: 10%;
  margin-top: 5%;
  border-radius: 30px;
}

.footer {
  margin-top: 5%;
  width: 100%;
  padding: 1%;
  background: #e25b22;
}

.designed {
  font-weight: 400;
  color: white;
  font-size: clamp(0.75rem, 4vw - 0.75rem, 1.125rem);
  font-family: "Open Sans", sans-serif;
}

.icon-wrapper {
  text-decoration: none;
  color: #24568e;
  cursor: pointer;
}

.icon-footer {
  width: 100vw;
  height: 100vh;
}

form {
  width: 100%;
  height: 100%;
}

.Nombre {
  width: 100%;
  margin-bottom: 5%;
  padding: 5%;
  border: 20px;
  background: white;
  border-radius: 20px;
  outline: none;
  font-size: clamp(0.75rem, 4vw - 1rem, 2.125rem);
  color: #24568e;
}

.emailp {
  width: 100%;
  margin-bottom: 5%;
  padding: 5%;
  border: 20px;
  background: white;
  border-radius: 20px;
  outline: none;
  font-size: clamp(0.75rem, 4vw - 1rem, 2.125rem);
  color: #24568e;
}

.Mensaje {
  width: 100%;
  margin-bottom: 5%;
  padding: 5%;
  border: 20px;
  background: white;
  border-radius: 20px;
  outline: none;
  font-size: clamp(0.75rem, 4vw - 1rem, 2.125rem);
  color: #24568e;
}

.btn_medium {
  width: 35%;
  height: 50%;
  padding: 3%;
}

.btn_medium {
  background: #e25b22;
  border-radius: 1rem;
  border: none;
}

.btn_medium {
  border: none;
  background-color: #e25b22;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  color: white;
  text-decoration: none;
  font-size: clamp(0.75rem, 4vw - 1rem, 3.125rem);
}

.Result {
  margin-top: 2%;
  color: white;
  font-size: clamp(0.75rem, 4vw - 1rem, 2.125rem);
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
}
