.erp_intro {
  margin-top: 10%;
}

.erp-intro-back {
  background-color: #24568e;
  width: 100%;
  padding-bottom: 3%;
}

.titulo_erp_wrapper {
  padding: 5% 5% 3% 5%;
  background: #e25b22;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15%;
}

.titulo_erp {
  color: white;
  font-size: clamp(0.063rem, 4vw + 0.15rem, 8rem);
  font-weight: 700;
  font-family: "Bebas Neue", sans-serif;
  line-height: 90%;
  text-align: center;
}

.parrafo_erp {
  margin-top: 2%;
  color: white;
  font-family: "Open Sans", sans-serif;
  font-size: clamp(0.75rem, 4vw - 1rem, 2.125rem);
  text-align: center;
}
