.construccion {
  margin-top: 5%;
}

.titulo-construccion {
  color: #24568e;
  font-size: clamp(0.063rem, 5vw + 0.25rem, 8rem);
  font-weight: 700;
  font-family: "Bebas Neue", sans-serif;
  line-height: 90%;
  margin-top: 2%;
  text-align: center;
}

.parrafo-construccion {
  color: #24568e;
  font-size: clamp(0.75rem, 5vw - 1rem, 2.125rem);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-align: center;
}
