.first-page {
  background-image: url("../assets/background1.jpg");
  background-size: cover;
  height: 100%;
  padding-bottom: 5%;
  padding-top: 5%;
}
.titulo {
  color: white;
  font-size: clamp(0.063rem, 5vw + 0.25rem, 8rem);
  font-weight: 700;
  font-family: "Bebas Neue", sans-serif;
  line-height: 90%;
}

.wrap-parrafo {
  color: white;
}

.parrafo {
  color: white;
  font-size: clamp(0.75rem, 5vw - 1rem, 2.125rem);
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.CTA {
  padding: 0.25em 1.25em 0.25em 1.25em;
  margin-top: 2%;
  border: none;
  border-radius: 30px;
  background-color: #e25b22;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 600;
  color: white;
  text-decoration: none;
  font-size: clamp(0.75rem, 5vw - 1rem, 3.125rem);
}

.CTA:hover {
  cursor: pointer;
}
