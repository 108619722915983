.secondpage {
  margin-top: 10%;
}

.wrap-title {
  background-color: #e25b22;
  padding: 5% 10% 3% 10%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titulo2 {
  color: white;
  font-size: clamp(0.063rem, 4vw + 0.15rem, 8rem);
  font-weight: 700;
  font-family: "Bebas Neue", sans-serif;
  line-height: 90%;
}

.wrap-text-second {
  display: flex;
  justify-content: center;
  align-items: center;
}

.second {
  margin-top: 5%;
}

.title- {
  color: #24568e;
  font-size: clamp(0.063rem, 4vw + 0.25rem, 8rem);
  font-weight: 700;
  font-family: "Bebas Neue", sans-serif;
  line-height: 90%;
}

.parrafo2 {
  font-weight: 400;
  color: #24568e;
  font-size: clamp(0.75rem, 4vw - 1rem, 2.125rem);
  font-family: "Open Sans", sans-serif;
}

.img-second {
  border-radius: 1rem;
  height: 100%;
  width: 100%;
}

hr {
  border: none;
  background-color: #e25b22;
  height: 1rem;
}
